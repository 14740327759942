.introduction {
  background-image: url("../images/intro-bg.png");
  position: relative;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  h1 {
    color: #fff;
    padding: 0.5rem 6rem;
    line-height: 1;
  }
  .logo-main {
    max-width: 18rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .entry-info {
    min-width: 45%;
    &:before {
      content: "";
      height: 2px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
      display: block;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    &:after {
      content: "";
      height: 2px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
      display: block;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}
.configuration {
  width: 100%;
  height: 100%;
  padding: 2rem;

  .car-block {
    padding: 2rem;
    height: 100%;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    background: #fff;
    .entry-thumb {
      margin-bottom: 1rem;
    }
    .entry-content {
      h3 {
        font-size: 1.3rem;
        margin-bottom: 0px;
        font-family: $font-bold;
      }
      p {
        margin-bottom: 0;
        font-size: 1.3rem;
      }
    }
    &:hover {
      box-shadow: 1px 1px 8px #2c2e354d;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
    }
  }
  .entry-segments {
    a {
      &.active {
        box-shadow: 1px 1px 8px #2c2e354d;
      }
    }
  }
}

.customization {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 1rem;
}

aside,
.configurator,
.drawer {
  align-self: stretch;
}
.finish-configuration {
  flex: 1;
  text-align: center;
  padding: 2rem;
  .btn {
    padding: 0.7rem 4rem;
    font-size: 1.2rem;
    border-radius: 0rem;
    width: 14rem;
    margin: 0.6rem;
    &:hover {
      background-color: $primary;
      border-color: $primary;
    }
    &:focus {
      box-shadow: 0px 0px 3px $primary;
    }
    &.active {
      background-color: $primary;
    }
  }
}
.instructions {
  padding: 2rem;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .list-disc {
    list-style-type: disc;
  }
  .list-number {
    list-style-type: decimal;
  }
  .instruction-list {
    li {
      padding: 0.5rem 0.7rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      background-color: #ededed;
      ul {
        li {
          display: list-item;
          font-size: 1.1rem;
          line-height: 1.3;
          padding: 0;
          margin-bottom: 0;
        }
      }

      span {
        font-family: $font-bold;
        color: #c6c6c6;
        margin-bottom: 0;
        line-height: 1;
        font-size: 3rem;
      }
      p {
        margin-left: 1rem;
        font-size: 1.1rem;
        line-height: 1.3;
        margin-bottom: 0;
      }
    }
  }
  .spacification-list {
    li {
      background-color: #ededed;
      padding: 0.9rem 1.8rem;
      margin-bottom: 1rem;
      width: 65%;
      &:nth-child(1) {
        background-color: #fffce6;
      }
      ul {
        li {
          font-family: $font-bold;
          font-size: 1.1rem;
          line-height: 1.8;
          padding: 0;
          margin-bottom: 0;
          background-color: transparent;
          width: 100%;
          small {
            font-family: $font-light;
          }
          &:nth-child(1) {
            background-color: transparent;
          }
        }
      }
    }
  }
  .chair-img {
    margin-top: -3rem;
  }
}

.generate-pdf {
  width: 40rem;
  overflow-y: auto;
  display: block;
  margin: 0 auto;
  .configurator {
    .bottom-info {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: end;
      padding: 2rem;
      p {
        margin: 0;
        color: #fff;
      }
      .box-text {
        background-color: #000;
        padding: 1.2rem;
        color: #fff;
        margin-left: 1rem;
        opacity: 0.8;
      }
    }
  }
  .drawer {
    padding: 0;
    flex-direction: column;
    .panel {
      width: 100%;
      table {
        &.finish-configuration {
          .btn {
            padding: 0.7rem 2rem;
            font-size: 1rem;
            width: 10rem;
            margin: 0.4rem;
          }
          tr {
            td {
              border: 0;
            }
          }
        }
      }
    }
  }
}


/* === RESPONSIVE === */

@media(max-width:780px){

  .introduction{
      padding: 30px;
  }

}