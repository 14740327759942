header {
  padding: 1.5rem;
  background-color: $secondary;
  position: relative;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &::before {
    content: "";
    background-image: url("../../images/header-left-vector.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    width: 38rem;
    height: 100%;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  &::after {
    content: "";
    background-image: url("../../images/lines.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 35rem;
    height: 100%;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    background-position: right;
  }
  .logo-header {
    .logo {
      z-index: 1;
      img {
        max-width: 16rem;
      }
    }
  }
  .segment {
    text-align: center;
    padding-right: 3rem;
    z-index: 1;
    img {
      max-width: 10rem;
    }
    h6 {
      color: #fff;
      margin-bottom: 0;
      font-family: $font-bold;
      margin-top: 0.5rem;
      font-size: 14px;
    }
    p {
      font-size: 12px;
      color: #fff;
      margin-bottom: 0;
    }
  }
}


/* === RESPONSIVE === */

@media(max-width:780px){
  header::after{
      display: none;
  }
  
  header::before{
      width:100%;
  }
  
  header{
      flex-direction: column;
  }
  
  header > *{
      padding: 0px !important;
  }
  
  .segment{
      margin-top: 25px;
  }
  
  .customization{
      flex-direction: column;
  }
}