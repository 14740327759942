

$latte: #ece0cb;
$cinder: #8ea49d;
$tormalina: #354143;
$nero: #333835;
$espresso: #46342f;
$corteccia: #6a3d2f;
$noce: #625444;
$cocconut: #8b4d31;
$biscotto: #a1653c;
$mou: #9c704c;
$cappuccino: #a38056;
$beige: #c6af7d;
$kayak: #c28a39;
$mandarin: #f45e20;
$rosoFerrari: #c63128;
$rossoAntico: #8e3033;
$mosto: #7b3431;
$abete: #2c4737;
$aloe: #4f5a3b;
$grigio: #4f5854;
$notte: #000;

$glacier: #dfe9dc;
$cranberryRot: #6f302e;
$crema: #bdbb91;
$alpaceGrau: #97a599;
$macchiatoBeige: #d4ceb1;
$tan: #ab5f45;
$sattleBraun: #68432c;
$kastanienBraun: #452a1a;
$brownStone: #3c3029;
$paprika: #953724;
$shalbyRed: #c9291b;
$blueElettrico: #184783;
$grigioScuro: #3a4944;
$antracite: #283531;
$darkBlack: #161816;


$colorPalets: (
    'latte': $latte,
    'cinder': $cinder,
    'tormalina': $tormalina,
    'nero': $nero,
    'espresso': $espresso,
    'corteccia': $corteccia,
    'noce': $noce,
    'cocconut': $cocconut,
    'biscotto': $biscotto,
    'mou': $mou,
    'cappuccino': $cappuccino,
    'beige': $beige,
    'kayak': $kayak,
    'mandarin': $mandarin,
    'rosso-ferrari': $rosoFerrari,
    'rosso-antico': $rossoAntico,
    'mosto': $mosto,
    'abete': $abete,
    'aloe': $aloe,
    'grigio': $grigio,
    'notte': $notte,
    'glacier': $glacier,
    'cranberry-rot': $cranberryRot,
    'crema': $crema,
    'alpaca-grau': $alpaceGrau,
    'macchiato-beige': $macchiatoBeige,
    'tan': $tan,
    'sattel-braun': $sattleBraun,
    'kastanien-braun': $kastanienBraun,
    'brown-stone': $brownStone,
    'paprika': $paprika,
    'shalby-red': $shalbyRed,
    'blu-elettrico': $blueElettrico,
    'grigio-scuro': $grigioScuro,
    'antracite': $antracite,
    'dark-black': $darkBlack
    );

    @each $name, $color in $colorPalets {
        .color-#{$name} {
            background-color: $color;
        }
        
    }
    .color-palate {
        padding-top: 1rem;
        padding-bottom: 1.5rem;
        ul {
            display: flex;
            flex-direction: column;
            padding-right: 1.5rem;
            li {
                a {
                 padding: 0.3rem;
                 width: 100%;
                 transition: all 0.2s ease-in-out;
                 p {
                     text-transform: uppercase;
                     font-family: $font-regular;
                     font-size: 0.9rem;
                 }
                 span {
                    transition: all 0.2s ease-in-out;  
                    transform: scale(1);
                 }
                 &.active {
                    background-color: #d6d6d6;
                 }
                }
                &:hover {
                    a {
                        background-color: #d6d6d6;
                        transition: all 0.2s ease-in-out;
                        span {
                            transform: scale(1.1);
                            transition: all 0.2s ease-in-out;
                          }
                        
                    }
                }
            }
        }
        
    }

    .color-palate [class*="color-"] {
        width: 1.4rem;
        height: 1.7rem;
        margin-right: 0.4rem;
    }

@media(max-width:780px){
    .color-palate [class*="color-"]{
      width: 25px;
      height: 25px;
    }
}