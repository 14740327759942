footer {
  background: $secondary;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  overflow: hidden;
  .social-media {
    padding: 1.5rem 2rem;
    padding-right: 5rem;
  }
  .entry-footer {
    padding: 1.5rem 2rem;
    padding-left: 10rem;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      background-image: url("../../images/footer-left.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 8rem;
      height: 100%;
      z-index: 0;
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      background-image: url("../../images/footer-right.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }
  p {
    z-index: 2;
  }
}

$social: ("facebook", "instagram", "linkedin");

@each $name in $social {
  .social-#{$name} {
    background-image: url("../../images/social/#{$name}.png");
    width: 1.5rem;
    height: 1.5rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: inline-block;
  }
}

/* === RESPONSIVE === */

@media(max-width:780px){
  
  footer .entry-footer::after, footer .entry-footer::before{
    display:none;
  }
  
  footer .entry-footer, footer .social-media{
    padding: 0px;
    position: static;
  }
  
  footer .entry-footer{
    display: flex;
    flex-direction: column-reverse;
  }
  
  footer{
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    overflow: initial;
    flex-direction: column-reverse;
  }
  
  footer > *{
    width: 100%;
  }
  
  footer p{
    margin-bottom: 0px;
    text-align: center;
    margin-top: 20px;
  }
  
  footer .social-media{
    justify-content: center;
  }
  
  .entry-footer .custom-btn-group{
    line-height: 0px;
    margin-bottom: 0px;
  }
  
  .btn-group.ms-auto{
    display: flex;
  }
  
  .btn-group.ms-auto > button{
    margin-top: 20px;
  }
  
  .ms-auto{
    margin-left: 0px !important;
  }
}

@media(max-width:450px){
  .entry-footer .custom-btn-group > *{
    width:100%;
    margin-bottom:15px;
  }
}