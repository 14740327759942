.accordion-button {
    justify-content: center;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-family: $font-light;
    padding: 0.8rem 1.25rem;

    &:after {
        display: none;
    }
}

.accordion-header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.accordion-button:not(.collapsed) {
    box-shadow: none !important;
    font-family: $font-bold;
}

.accordion-body {
    padding: 0px;

    h3 {
        font-size: 1.2rem;
        text-align: center;
        font-family: $font-bold;
        text-transform: uppercase;
        
        .accordion-button {
            font-size: 1.3rem;
            padding: 0.5rem 0rem;
        }
    }
    h4 {
        font-size: 1rem;
        text-align: center;
        font-family: $font-bold;
        text-transform: uppercase;
        
        .accordion-button {
            font-size: 1rem;
            padding: 0.5rem 0rem;
        }
    }
}


.accordion-item:first-of-type {
    border-top: 1px solid $secondary !important;
}

.accordion-item:last-of-type {
    border-bottom: 1px solid $secondary !important;
}


@media(max-width:780px){
    
    aside{
        overflow: initial;
    }
    
    .drawer{
        display: flex;
        overflow: initial;
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 30px;
    }
    
    .accordion-button{
        padding: 15px 0 !important;
    }
    
    .accordion-button:after{
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        margin-left: 0px;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E") !important;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        position: absolute;
        right: 10px;
        background-size: contain;
        filter: brightness(0) invert(1);
    }
    
    .accordion-button.collapsed:after{
        background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E") !important;
        filter: brightness(1);
    }
    
    #myCollapse .accordion-body .col-4{
      width: 100%;
      margin-right:0px !important;
    }
    
    #myCollapse .list-group.list-group-flush .accordion-body > .d-flex{
      flex-wrap: wrap;
    }
    
    #myCollapse .design-pattern li{
      width: 33%;
    }
}