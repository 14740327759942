.configurator {
    flex: 1;
    max-width: 100%;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .symbols{
        position: absolute;
        display: flex;
        width: 100%;
        left: 0;
        bottom: 0;
        justify-content: space-between;
        padding: 1.5rem 2rem;
        align-items: center;
        img{
            max-width: 3.5rem;
        }
    }
    .start-configuration{
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: #0000005e;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        max-width: 20rem;
        max-height: 21rem;
        margin: auto;
        top: 0;
        bottom: 0;
        outline: 1px solid #fff;
        outline-style: solid;
        outline-offset: 14px;
        outline-style: dashed;
        outline-width: 3px;
        img{
            max-width: 10rem;
            width: 100%;
            height: auto;
            margin-bottom: 1rem;
        }
        h4{
            color: #fff;
        }
    }
}

@media(max-width:780px){
    .configurator{
      margin-top: 60px !important;
    }
    
    .configurator .start-configuration{
      max-width: 250px;
      max-height: 250px;
    }
    
    .symbols.top-right{
      top: -64px !important;
      background: #000;
      padding: 10px;
      justify-content: center !important;
    }
    
    .applicaScelteWrapper{
      bottom: 0px;
      width: 100%;
      background: #fff;
      padding: 15px 0px;
      max-width: 350px;
      border-top: 1px solid rgba(0,0,0,0.3);
    }
}