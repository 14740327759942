@media(max-width:780px){
     
    .configuration .entry-title{
       margin-top: 0px !important;
       margin-bottom: 20px;
    }
    
    .configuration .entry-segments .col-sm-3{
       margin-top: 0px !important;
       width: 100%;
    }
    
    .car-block{
       box-shadow: 0 5px 15px rgba(0,0,0,.08);
    }
    
    .configuration .entry-segments .g-5{
       --bs-gutter-y: 0px !important;
       gap: 35px;
    }
    
    /* === CONFIGURATOR === */
    
    .instructions .border-end{
      border-right: 0px !important;
    }
    
    .configurator .d-flex.h-100.w-100.text-white{
          min-height: 400px;
    }
    
    .configurator > canvas{
      min-height: 400px;
    }
   
}