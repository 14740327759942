.contact{
    .contact-section{
        flex: 1;
        padding: 2rem;
        overflow-y: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
          }
        h2{
            text-align: center;
        }
        .contact-form{
            padding: 2rem;
            input{
                height: 34px;
                &::placeholder{
                    font-family: $font-italic;
                }
            }
            textarea{
                &::placeholder{
                    font-family: $font-italic;
                }
            }
            .form-text{
                font-family: $font-italic;
            }
            
        }
        .generate-pdf{
            background-color: $lightyellow;
            padding: 2rem;
            border: 1px solid $secondary;
            display: flex;
            align-items: center;
            p{
                font-size: 1.2rem;
                line-height: 1.3;
                margin-bottom: 0;
            }
        }
    }
}

@media(max-width:780px){
    .contact-section{
      padding: 30px 0px !important;
    }
    
    .contact .contact-section .generate-pdf{
      flex-wrap: wrap;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
    }
    
    .contact .contact-section .contact-form{
      margin-bottom: 20px;
    }
    
    .generate-pdf *{
      text-align: center;
    }
    
    .generate-pdf .btn.btn-primary{
      margin-left: 0px !important;
      margin-top: 15px;
      width: 100%;
    }
    
    .contact .contact-section{
      overflow: visible;
    }
}