
$traforature: ("si", "no");

@each $name in $traforature {
  .traforature-#{$name} {
    width: 100%;
    height: 8rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: inline-flex;
  }
}
