#root {
  min-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Calibri-regular";
  font-weight: normal;
  // overflow: hidden;
}
.custom-ul-wrapper {
  flex-direction: unset !important;
}

.custom-clickable-link {
  cursor: pointer;
}
.introduction {
  .logo-main {
    width: 100%;
    max-width: 28rem;
  }
}
header {
  .logo {
    img {
      max-width: 23rem;
    }
  }
}

.customization {
  .configurator {
    background-color: #000;
  }
}
.entry-footer {
  .custom-btn-group {
    z-index: 1;
  }
}

.symbols {
  &.top-right{
    justify-content: right;
    top: 0;
    bottom: auto;
    img{
      cursor: pointer;
      margin-right: 0.7rem;
      width: 2.7rem;
    }
    .fullScreenImg{
      border: 0.5px solid #fff;
      padding: 8px;
      width: 2.7rem;
      border-radius: 0.2rem;
    }
    .cam-4 {
      margin-right: 2rem;
    }
  }
}
page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
}
page {
  size: A4;
  margin: 0;
}
@media print {
  .table-do-not-break {
    td{
      page-break-inside: avoid;
    }
  }
}