
.accordian-seprater {
    width: 100%;
    height: 1px;
    background-color: $dark;
    display: block;
    margin: 0.5rem 0rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal !important;
}

.entry-title {
    p {
        font-size: 1.2rem;
    }
}


.custom-scroller {
    scrollbar-width: thin;
    scrollbar-color: rgba($color: #000000, $alpha: 0.05) transparent;
}

/* total width */
.custom-scroller::-webkit-scrollbar {
    background-color:transparent;
    width:4px
}

/* background of the scrollbar except button or resizer */
.custom-scroller::-webkit-scrollbar-track {
    background-color:transparent;
}
.custom-scroller::-webkit-scrollbar-track:hover {
    background-color:transparent;
}

/* scrollbar itself */
.custom-scroller::-webkit-scrollbar-thumb {
    background-color:rgba($color: #000000, $alpha: 0.2);
    border-radius:4px;
}
.custom-scroller::-webkit-scrollbar-thumb:hover {
    background-color:rgba($color: #000000, $alpha: 0.2);
}

/* set button(top and bottom of the scrollbar) */
.custom-scroller::-webkit-scrollbar-button {display:none}


@media(max-width:780px){
    .instructions .spacification-list li{
      background-color: #ededed;
      margin-bottom: 1rem;
      padding: .9rem 1.8rem;
      width: 100%;
    }
}