
$primary: #0095db;
$secondary: #000000;
$danger: #be1d2c;
$lightyellow :#fffbcc;
$success:#0c6638;


$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "danger": $danger,
    "success":$success,
  );
  

$custom-colors: (
    "lightyellow" :$lightyellow,
  );
  
  // Merge the maps
  $theme-colors: map-merge($theme-colors, $custom-colors);



  //Grays

  $white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ededed;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;


  $grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);




  // Merge the maps
  $theme-colors: map-merge($theme-colors, $grays);

  $font-regular: 'Calibri-regular';
  $font-bold: 'Calibri-bold';
  $font-light: 'Calibri-light';
  $font-italic: 'Calibri-italic';

  $weights:(
    'regular': $font-regular,
    'bold': $font-bold,
    'light': $font-light,
    'italic': $font-italic
  );

  //Utilities
$utilities: (
  "font-weight": (
    responsive: true,
    property: font-family,
    class: fw,
    values: (
        light: $font-light,
        normal: $font-regular,
        bold:  $font-bold,
      )
  ),
);


  @each $name, $fonts in $weights {
    .font-#{$name} {
      font-family: $fonts;
    }
  }

  $table-variants: (
  "dark":       $secondary,
);
