$design: ("cube", "daytona", "diamond", "double-cube", "gothic", "highway", "highway-v", "hive", "montecarlo", "rs",  "sunrise","tron");

$embossed: ("aspide", "atom", "canestrino", "corteccia", "drone", "gecko", "katana", "pied-de-poule", "plait", "ruffle");

@each $name in $design {
  .design-#{$name} {
    // background-image: ("/images/design/design-#{$name}.png");
    width: 5rem;
    height: 6rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: inline-flex;
  }
}

@each $name in $embossed {
  .embossed-#{$name} {
    // background-image: "/images/embossed/embossed-#{$name}.png" !important;
    width: 100%;
    height: 5rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    display: inline-flex;
  }
}

%pattern {
  width: calc(100% / 4);
  p {
    white-space: nowrap;
    text-transform: uppercase;
    font-family: $font-bold;
    font-size: 0.915rem;
    padding: 0rem 0.3rem;
    transition: all 0.2s ease-in-out;
    color: lighten($secondary, $amount: 50);
  }
  span {
    transition: all 0.2s ease-in-out;
  }
  a {
    &:hover {
      p {
        transition: all 0.2s ease-in-out;
        background-color: #d6d6d6;
        color: $secondary;
      }
      span {
        transform: scale(1.1);
        transition: all 0.2s ease-in-out;
      }
    }
    &.active {
      p {
        background-color: #d6d6d6;
      }
    }
  }
  &.list-inline-item:not(:last-child) {
    margin-right: 0px;
  }
}

.design-pattern {
  padding-top: 1rem;
  li {
    @extend %pattern;
  }
}

.embossed-pattern {
  padding-top: 1rem;
  li {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    @extend %pattern;
  }
}
