@font-face {
  font-family: "Calibri-italic";
  src: url("../fonts/Calibri-Italic.eot");
  src: url("../fonts/Calibri-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/Calibri-Italic.woff2") format("woff2"),
    url("../fonts/Calibri-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Calibri-regular";
  src: url("../fonts/Calibri.eot");
  src: url("../fonts/Calibri.eot?#iefix") format("embedded-opentype"), url("../fonts/Calibri.woff2") format("woff2"), url("../fonts/Calibri.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calibri-bold";
  src: url("../fonts/Calibri-Bold.eot");
  src: url("../fonts/Calibri-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Calibri-Bold.woff2") format("woff2"), url("../fonts/Calibri-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calibri-lightItalic";
  src: url("../fonts/Calibri-LightItalic.eot");
  src: url("../fonts/Calibri-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Calibri-LightItalic.woff2") format("woff2"),
    url("../fonts/Calibri-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Calibri-light";
  src: url("../fonts/Calibri-Light.eot");
  src: url("../fonts/Calibri-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Calibri-Light.woff2") format("woff2"), url("../fonts/Calibri-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calibri-boldItalic";
  src: url("../fonts/Calibri-BoldItalic.eot");
  src: url("../fonts/Calibri-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Calibri-BoldItalic.woff2") format("woff2"),
    url("../fonts/Calibri-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
