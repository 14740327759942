$design: ("cube", "daytona", "diamond", "double-cube", "gothic", "highway", "highway-v", "hive", "montecarlo", "rs", "sunrise", "tron", "nessuno");

$embossed: ("aspide", "atom", "canestrino", "corteccia", "drone", "gecko", "katana", "pied-de-poule", "plait", "ruffle", "nessuno");

$filati: (
  "nero",
  "antracite",
  "grigio",
  "ghiaccio",
  "bianco",
  "cioccolato",
  "bordeaux",
  "tabacco",
  "sabbia",
  "crema",
  "panna",
  "giallo",
  "arancione",
  "rosso",
  "cartier",
  "verde",
  "blu",
  "blu-elettrico",
  "giallo-fluo",
  "verde-fluo",
  "turchese",
  "viola",
  "nessuno"
);

.history-design {
  max-height: 5rem;
  margin: auto;
  display: flex;
}
.history-embossed {
  max-height: 7rem;
  margin: auto;
  display: flex;
}
.history-filati {
  max-height: 5rem;
  margin: auto;
  display: flex;
}
span[class^="design-"],
span[class^="embossed-"],
span[class^="filati-"] {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-flex;
}
span[class^="design-"] {
  width: 5rem !important;
  height: 6rem;
}
span[class^="embossed-"] {
  width: 5rem !important;
  height: 6rem;
}
span[class^="filati-"] {
  width: 3rem;
  height: 3rem;
}
.configurator {
  justify-content: center;
  display: flex;
  align-items: center;
}

.hover-thumb-img {
  width: 100% !important;
  height: 9rem;
  max-height: 9rem !important;
  max-width: 100% !important;
  height: auto !important;
}
.finish-configuration {
  .btn {
    &.active {
      background-color: #0095db;
      border-color: #0095db;
    }
  }
}
.custom-pdf-drawer {
  .panel {
    width: 33rem;
  }
  width: auto;
  .table {
    min-width: 50%;
    width: 50%;
    .segment {
      text-align: center;
      padding-right: 3rem;
      z-index: 1;
    }
  }
}
